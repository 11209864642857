import { defineStore } from 'pinia'

export const usePermissions = defineStore({
  id: 'permission',
  state: () => ({
    permissions: {}
  }),
  actions: {
    setPermissions(permissions) {
      this.permissions = permissions
    },
    setClearAll() {
      this.permissions = {}
    }
  },
  getters: {
    getPermissions(state) {
      return state.permissions
    }
  },
  persist: true
})
