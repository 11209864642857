<template>
  <slot />
</template>

<script lang="ts">
export default {
  name: 'AppLayoutDefault',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<style scoped></style>
