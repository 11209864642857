<template>
  <main>
    <AppLayout />
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AppLayout from '@/layouts/AppLayout.vue'
import { provideUserPermissions } from '@/providers/userPermissionsProvider'

export default defineComponent({
  name: 'App',
  components: {
    AppLayout
  },
  setup() {
    provideUserPermissions()
  }
})
</script>

<style>
[v-cloak] {
  display: none;
}
</style>
