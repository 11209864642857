import { reactive, provide, inject } from 'vue'

const state = reactive({
  userPermissions: []
})

const setUserPermissions = permissions => {
  state.userPermissions = permissions
}

const getUserPermissions = () => {
  return state.userPermissions
}

const hasPermissionOnResource = resource => {
  return state.userPermissions.some(permission => permission[resource])
}

const hasPermissionBtn = (resource, action) => {
  return state.userPermissions.some(permission =>
    permission[resource]?.includes(action)
  )
}

const hasRole = role => {
  return state.userPermissions.some(permission =>
    permission.roles?.includes(role)
  )
}

const hasPermissionRouter = (resource, router) => {
  return state.userPermissions.some(permission =>
    permission[resource]?.includes(router)
  )
}

export const userPermissionsProvider = {
  setUserPermissions,
  getUserPermissions,
  hasPermissionOnResource,
  hasPermissionBtn,
  hasRole,
  hasPermissionRouter
}

export const provideUserPermissions = () => {
  provide('userPermissionsProvider', userPermissionsProvider)
}

export const useUserPermissions = () => {
  const userPermissionsProvider = inject('userPermissionsProvider')
  if (!userPermissionsProvider) {
    throw new Error('No userPermissionsProvider provided!!!')
  }
  return userPermissionsProvider
}
