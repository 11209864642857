<template>
  <fwb-modal v-if="props.show" @close="closeModal" size="3xl">
    <template #header>
      <h3>Termos de Uso</h3>
    </template>
    <template #body>
      <div class="p-10 overflow-y-auto h-[calc(100vh-280px)]">
        <ol type="I">
          <li>
            O uso deste aplicativo ("App") está sujeito aos seguintes termos de
            uso ("Termos"). Ao utilizar o App, você concorda em cumprir esses
            Termos. Se você não concordar com esses Termos, não utilize o App.
          </li>
          <li>
            O App é destinado exclusivamente para uso em segurança do trabalho.
            Não deve ser usado para qualquer outra finalidade.
          </li>
          <li>
            O App é fornecido "como está" e "conforme disponível". Não
            garantimos que o App será ininterrupto ou livre de erros, e não nos
            responsabilizamos por quaisquer danos decorrentes do uso do App.
          </li>
          <li>
            Você é responsável por manter a confidencialidade de sua conta e
            senha, e por todas as atividades que ocorrem sob sua conta.
          </li>
          <li>
            Não devemos ser responsáveis por quaisquer perdas ou danos causados
            por sua falha em proteger a confidencialidade de sua conta e senha.
          </li>
          <li>
            Não devemos ser responsáveis por quaisquer perdas ou danos
            decorrentes do uso não autorizado do seu dispositivo ou conta.
          </li>
          <li>
            Você concorda em notificar-nos imediatamente de qualquer uso não
            autorizado de sua conta ou qualquer outra violação de segurança.
          </li>
          <li>
            Você concorda em não usar o App para qualquer finalidade ilegal ou
            não autorizada.
          </li>
          <li>
            O App pode conter links para sites de terceiros. Esses links são
            fornecidos apenas para sua conveniência e não controlamos o conteúdo
            desses sites. Não nos responsabilizamos por quaisquer danos ou
            prejuízos decorrentes do uso de tais sites.
          </li>
          <li>
            Reservamos o direito de modificar esses Termos a qualquer momento.
            Seus usos contínuos do App após tais modificações constituirão sua
            aceitação de tais modificações.
          </li>
          <li>
            Esses Termos serão regidos e interpretados de acordo com as leis do
            país onde o App está disponível. Qualquer litígio decorrente do uso
            do App será resolvido exclusivamente na justiça desse país.
          </li>
        </ol>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-between">
        <button
          @click="closeModal"
          class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
        >
          Fechar
        </button>
        <button
          @click="acceptModal"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Aceitar
        </button>
      </div>
    </template>
  </fwb-modal>
</template>

<script setup lang="ts">
import { FwbModal } from 'flowbite-vue'

const props = defineProps<{ show: boolean }>()

const emit = defineEmits(['close', 'accept'])

const closeModal = () => {
  emit('close')
}

const acceptModal = () => {
  emit('accept')
}
</script>

<style scoped>
ol {
  list-style-type: upper-roman;
}
</style>
