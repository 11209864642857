<template>
  <div class="horizontal-menu">
    <button @click="toggleMenu" class="toogle-btn">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16M4 18h16"
        />
      </svg>
    </button>
  </div>
</template>

<script setup>
const emit = defineEmits(['toggleMenu'])

const toggleMenu = () => {
  emit('toggleMenu')
}
</script>

<style scoped>
.toogle-btn {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: rgb(14, 14, 41);
}
</style>
