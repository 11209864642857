import { createApp } from 'vue'
import './style.css'
import 'floating-vue/dist/style.css'
import router from './routers'
import { i18n } from './config/internationalization'

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import Colada, { PiniaColadaPlugin } from 'colada-plugin'

import FloatingVue from 'floating-vue'

import { userPermissionsProvider } from './providers/userPermissionsProvider'

import CKEditor from '@ckeditor/ckeditor5-vue'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faBoxOpen,
  faChartLine,
  faEdit,
  faInfoCircle,
  faPhone,
  fas,
  faTrash,
  faUser
} from '@fortawesome/free-solid-svg-icons'


import { far } from '@fortawesome/free-regular-svg-icons'
import App from './App.vue'
import axios from 'axios'
import { VueResponsiveness } from 'vue-responsiveness'

import Toast, { PluginOptions } from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

import { registerLayouts } from './layouts/register_layouts'

import * as Sentry from '@sentry/vue'

const pinia = createPinia()

library.add(
  far,
  fas,
  faUser,
  faInfoCircle,
  faPhone,
  faChartLine,
  faBoxOpen,
  faEdit,
  faTrash
)

const options: PluginOptions = {
  transition: 'Vue-Toastification__fade',
  maxToasts: 20,
  newestOnTop: true,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(t => t.type === toast.type).length !== 0) {
      return false
    }
    return toast
  }
}

const app = createApp(App)
registerLayouts(app)

app.use(CKEditor)
app.use(i18n)
app.config.globalProperties.$axios = axios
app.component('font-awesome-icon', FontAwesomeIcon)
// app.use(createPinia().use(piniaPluginPersistedstate))
app.use(pinia)
pinia.use(piniaPluginPersistedstate)
pinia.use(PiniaColadaPlugin)
app.use(Colada)
app.use(VueResponsiveness,
  {
    // TailwindCSS breakpoints for device sizes
    // Use 'small' for mobile devices
    small: 0,
    // Use 'medium' for tablets in portrait orientation
    medium: 768,
    // Use 'large' for tablets in landscape orientation and laptops
    large: 1024,
    // Use 'xlarge' for large desktops
    xlarge: 1280
  }
)

app.use(Toast, options)

app.use(FloatingVue, {
  themes: {
    'info-tooltip': {
      $extend: 'tooltip',
      $resetCss: false
    }
  }
})
app.config.globalProperties.$swal = Swal

app.provide('dataPermissions', userPermissionsProvider)

// Sentry.init({
//   app,
//   dsn: 'https://2bcd58f937a746795e76f167efe9b4d8@o4507073349877760.ingest.us.sentry.io/4507073352040448',
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration()
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// })
//

app.use(router)
app.mount('#app')
