<template>
  <div>App Layout Reports</div>
  <slot />
</template>

<script lang="ts">
export default {
  name: 'AppLayoutReports',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<style scoped></style>
