import { createI18n } from 'vue-i18n'
import en from './languages/en.json'
import pt from './languages/pt.json'

const messages = {
  en,
  pt
}

export const i18n = createI18n({
  locale: 'pt', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages // set locale messages
})
