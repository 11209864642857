import { getAPI } from '@/api/axios_api'

const api = getAPI()

async function getMfaQrcode(encodeparam) {
  try {
    const response = await api.get(`mfa/get-mfa-qrcode?email=${encodeparam}`)
    return response.data
  } catch (error) {
    throw new Error('Failed to fetch mfa qrcode')
  }
}

async function setMFAStatus(data) {
  try {
    const response = await api.post('/mfa/set-mfa-status', data)
    return response.data
  } catch (error) {
    throw new Error('Failed to set mfa status')
  }
}

function getService() {
  return {
    getMfaQrcode: (encodeparam) => getMfaQrcode(encodeparam),
    setMFAStatus: (data) => setMFAStatus(data)
  }
}

export { getService }
