import { useAuthenticationStore, useGroup, usePermissions } from '@/store'
import { config } from '@/config'

export function Authentication() {
  this.API = config.api.url

  this.setStoreData = async function(data) {
    const authStore = useAuthenticationStore()
    const groups = useGroup()
    if (data.access_token != null) {
      authStore.setToken(data.access_token)
      authStore.setRefreshToken(data?.refresh_token || '')
      authStore.setExpires(data?.expires || 0)
    }
    if (data.user) {
      authStore.setUserData(data.user)
    }
    authStore.setUserAuth(true)
    if (data.empresa) {
      groups.setEmpresa(data.empresa)
    }
    if (data.grupo) {
      groups.setGroup(data.grupo)
    }
    if (data.grupos) {
      groups.setGroups(data.grupos)
    }
    if (data.permissions) {
      const permissions = usePermissions()
      permissions.setPermissions(data.permissions)
    }
  }

  this.login = async function(username, password) {
    localStorage.clear()

    const myHeaders = new Headers()
    myHeaders.append('accept', 'application/json')
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
    myHeaders.append('database', 'ate_sg3')

    const urlencoded = new URLSearchParams()
    urlencoded.append('username', username)
    urlencoded.append('password', password)

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    }

    const response = await fetch(`${this.API}/login`, requestOptions)
    if (response.status !== 200) {
      return {
        login: {
          message: 'Error',
          email_message: 'Error',
          mfa: false,
          statusCode: response.status
        }
      }
    }
    const data = await response.json()
    return data
  }

  this.loginMfa = async function(tempToken, code) {
    const myHeaders = new Headers()
    myHeaders.append('accept', 'application/json')
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

    const urlencoded = new URLSearchParams()
    urlencoded.append('temp_token', tempToken)
    urlencoded.append('mfa_code', code)

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    }

    const response = await fetch(`${this.API}/login/mfa`, requestOptions)
    if (response.status !== 200) {
      return {
        login: {
          message: 'Error',
          email_message: 'Error',
          mfa: false,
          statusCode: response.status
        }
      }
    }
    const data = await response.json()
    return data
  }
}
