export const config = {
  api: {
    url: import.meta.env.VITE_API_URL,
    endpoints: {
      login: 'login',
      register: 'register',
      logout: 'logout'
    }
  }
}
