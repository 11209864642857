import { v4 as uuidv4 } from 'uuid'
import { faker } from '@faker-js/faker'

const formatDate = (date) => {
  const format2Digits = (value) => {
    return value < 10 ? `0${value}` : value
  }
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  return `${format2Digits(day)}/${format2Digits(month)}/${year}`
}

export function generateFakeData() {
  return {
    id: uuidv4(),
    name: faker.commerce.productName(),
    description: faker.commerce.productDescription(),
    quantity: faker.datatype.number(),
    createdAt: formatDate(faker.date.past()),
    deletedAt: formatDate(faker.date.recent())
  }
}
