import { getAPI } from '@/api/axios_api'

const url = '/users/usuarios'
const api = getAPI()

async function getUsuarios() {
  try {
    const response = await api.get(url)
    return response.data
  } catch (error) {
    throw new Error('Failed to fetch usuarios')
  }
}

async function getUsuariosPaginado(page, limit) {
  try {
    const response = await api.get(`${url}?page=${page}&limit=${limit}`)
    return response.data
  } catch (error) {
    throw new Error('Failed to fetch usuarios')
  }
}

async function getUsuariosFilter(page, limit, filter, key, order) {
  try {
    const response = await api.get(
      `${url}/filter?page=${page}&limit=${limit}&search=${filter}&orderby=${key}&order=${order}`
    )
    return response.data
  } catch (error) {
    throw new Error('Failed to fetch usuarios')
  }
}

async function getUsuariosById(id) {
  try {
    const response = await api.get(`${url}/${id}`)
    return response.data
  } catch (error) {
    throw new Error('Failed to fetch usuarios')
  }
}

async function addUsuarios(usuarios) {
  try {
    const response = await api.post(url, usuarios)
    return response.data
  } catch (error) {
    throw new Error('Failed to add usuarios')
  }
}

async function updateUsuarios(usuarios) {
  try {
    const response = await api.put(`${url}/${usuarios.id}`, usuarios)
    return response.data
  } catch (error) {
    throw new Error('Failed to update usuarios')
  }
}

async function deleteUsuarios(id) {
  try {
    const response = await api.delete(`${url}/${id}`)
    return response.data
  } catch (error) {
    throw new Error('Failed to delete usuarios')
  }
}

async function setNewPassword(id, senha) {
  try {
    const response = await api.put(
      `${url}/${id}/update-password?senha=${senha}`
    )
    return response.data
  } catch (error) {
    throw new Error('Failed to update password')
  }
}

async function addOrUpdateUsuarios(usuarios) {
  try {
    const response = await api.post(`${url}`, usuarios)
    return response.data
  } catch (error) {
    throw new Error('Failed to add or update usuarios')
  }
}

function getService() {
  return {
    getUsuarios: () => getUsuarios(),
    getUsuariosById: id => getUsuariosById(id),
    getUsuariosPaginado: (page, limit) => getUsuariosPaginado(page, limit),
    addUsuarios: usuarios => addUsuarios(usuarios),
    updateUsuarios: usuarios => updateUsuarios(usuarios),
    deleteUsuarios: id => deleteUsuarios(id),
    getUsuariosFilter: (page, limit, filter, key, order) =>
      getUsuariosFilter(page, limit, filter, key, order),
    addOrUpdateUsuarios: usuarios => addOrUpdateUsuarios(usuarios),
    setNewPassword: (id, senha) => setNewPassword(id, senha)
  }
}

export { getService }
