// path: src/layouts/register.ts

import { App } from 'vue'

interface Layout {
  default: {
    name: string;
    __hmrId: string;
    __file: string;
    components?: {
      [key: string]: {
        __name: string;
        __hmrId: string;
        __scopeId?: string;
        __file: string;
        emits?: string[];
        props?: {
          [key: string]: {
            required?: boolean;
          };
        };
      };
    };
    inheritAttrs?: boolean;
    customOptions?: any;
  };
}

type Layouts = {
  [key: string]: Layout;
}

export function registerLayouts(app: App<Element>) {
  const layouts: Layouts = import.meta.glob('./**/*.vue', { eager: true })
  Object.entries(layouts).forEach(([, layout]) => {
    app.component(layout?.default?.name, layout?.default)
  })
}
