<template>
  <section>
    <div id="message">
      <div v-if="message" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <span class="block sm:inline">{{ message }}</span>
      </div>
    </div>
    <div class="flex flex-col items-center justify-center p-6 bg-white shadow-md lg:w-[420px] w-[100%] mx-auto">
      <div class="w-full max-w-md">
        <div>
          <img alt="Company Logo" class="w-auto h-[200px] mx-auto" src="./../../assets/colgate-logo-2021.svg" />
          <h2 class="mt-6 text-3xl font-extrabold text-center text-gray-900">
            {{ $t('login.title') }}
          </h2>
        </div>
        <div class="mt-8">
          <div class="mt-6">
            <form action="#" class="space-y-6" method="POST">
              <div>
                <label class="block text-sm font-medium text-gray-700" for="email-address">
                  {{ $t('login.email') }}
                </label>
                <div class="mt-1">
                  <input id="email-address" v-model="email" :class="{
                    'border-red-500': emailError,
                    'border-gray-300': !emailError
                  }" autocomplete="email"
                    class="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    name="email" required type="email" v-on:blur="validateEmailField" />
                </div>
                <div>
                  <span v-if="emailError" class="text-red-500 text-xs italic">{{
                    $t('login.email_error')
                  }}</span>
                </div>
              </div>
              <div class="space-y-1">
                <label class="block text-sm font-medium text-gray-700" for="password">
                  {{ $t('login.password') }}
                </label>
                <div class="mt-1">
                  <input id="password" v-model="password" autocomplete="current-password"
                    class="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    name="password" required type="password" />
                </div>
              </div>
              <div class="flex items-center justify-between flex-col lg:flex-row">
                <div class="flex items-center">
                  <input id="remember-me" class="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    name="remember-me" type="checkbox" />
                  <label class="block ml-2 text-sm text-gray-900" for="remember-me">
                    {{ $t('login.remember_me') }}
                  </label>
                </div>
                <div class="text-sm">
                  <a class="font-medium text-indigo-600 hover:text-indigo-500" href="/forgot-password">
                    {{ $t('login.forgot_password') }}
                  </a>
                </div>
              </div>
              <div>
                <button id="login-button"
                  class="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  type="submit" @click.prevent="login">
                  {{ $t('login.sign_in') }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { ref, provide } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import { Authentication } from '@/services/AuthenticationService'
import { response } from '@/interfaces/ILoginResponseData'

import emailIsValid from '@/utils'

import { useUserPermissions } from '@/providers/userPermissionsProvider'
const { setUserPermissions } = useUserPermissions()

const email = ref('')
const password = ref('')
const emailError = ref(false)
const message = ref('')

const router = useRouter()
const route = useRoute()
const auth = new Authentication()

const redirect: any = route.query.redirect || '/home'

const byPassAssinaturas = () => {
  if (redirect.includes('permissao-entrada/assinaturas')) {
    router.push({ path: redirect })
  }
}

function toggleWaitingState(isWaiting: boolean) {
  const waitingbtn = document.getElementById('login-button') as HTMLElement
  if (isWaiting) {
    waitingbtn.classList.add('cursor-not-allowed', 'opacity-50')
    waitingbtn.innerHTML =
      "<i class='fas fa-spinner fa-spin'></i>  " + waitingbtn.innerHTML
  } else {
    waitingbtn.classList.remove('cursor-not-allowed', 'opacity-50')
    if (waitingbtn.hasChildNodes()) {
      waitingbtn.removeChild(waitingbtn.childNodes[0])
    }
  }
}

function validateEmailField() {
  emailError.value = !email.value || !emailIsValid(email.value)
}

async function attemptLogin() {
  if (!emailIsValid(email.value) || !password.value) {
    emailError.value = true
    return
  }

  toggleWaitingState(true)

  try {
    const response: response = await auth.login(email.value, password.value)
    handleLoginResponse(response)
  } catch (error) {
    console.error(error)
  }
}

function handleLoginResponse(response: response) {
  if (response.login.statusCode === 404) {
    toggleWaitingState(false)
    message.value = 'Usuário ou senha incorretos'
    return
  }

  if (response.login.statusCode === 200 && !('mfa' in response.login)) {
    toggleWaitingState(false)
    auth.setStoreData(response.login)
    setUserPermissions(response.login.permissions)
    // Se o redirect estiver apontando para a página de assinaturas, redireciona para a página de AssinaturasPET
    if (redirect.includes('permissao-entrada/assinaturas')) {
      byPassAssinaturas()
    } else {
      router.push({ name: 'Home', query: { redirect } })
    }
  } else {
    toggleWaitingState(false)
    if (response.login.temp_token) {
      sessionStorage.setItem('temp_token', response.login.temp_token)
      if (redirect.includes('permissao-entrada/assinaturas')) {
        byPassAssinaturas()
      } else {
        router.push({ name: 'MfaAuth', query: { redirect } })
      }
    }
  }
}

const login = () => attemptLogin()
</script>

<style scoped></style>
