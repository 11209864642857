import { defineStore } from 'pinia'

export const useAuthenticationStore = defineStore({
  id: 'authenticationStore',
  state: () => ({
    accessToken: '',
    refreshToken: '',
    expires: 0,
    user: {},
    userAuthenticated: false,
    credentials: {
      username: '',
      password: ''
    }
  }),
  getters: {
    isAuthenticated() {
      return this.userAuthenticated
    },
    getAccessToken() {
      return this.accessToken
    }
  },
  actions: {
    setCredentials(username, password) {
      this.credentials.username = username
      this.credentials.password = password
    },
    setToken(accessToken) {
      this.accessToken = accessToken
    },
    setRefreshToken(refreshToken) {
      this.refreshToken = refreshToken
    },
    setExpires(expires) {
      this.expires = expires
    },
    setUserData(user) {
      this.user = user
    },
    acceptTerms() {
      if (this.user) {
        this.user.aceitoutermos = String(true)
      }
    },
    setUserAuth(data) {
      this.userAuthenticated = data
    },
    logout() {
      localStorage.clear()
      this.accessToken = ''
      this.refreshToken = ''
      this.user = {}
      this.userAuthenticated = false
    }
  },
  persist: true
})
