import { reactive, ref } from 'vue'
import { defineStore, acceptHMRUpdate } from 'pinia'

export const usePetStore = defineStore({
  id: 'petDTOStore',
  state: () => ({
    petDATA: reactive({}),
    statusIndicatorDashBoard: ref('')
  }),
  actions: {
    setPETData(data) {
      this.petDATA = data
    },
    setStatusIndicatorDashBoard(status) {
      this.statusIndicatorDashBoard.value = status
    }
  },
  getters: {
    getPetData() {
      return this.petDATA
    },
    getStatusIndicatorDashBoard() {
      return this.statusIndicatorDashBoard.value
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePetStore, import.meta.hot))
}
