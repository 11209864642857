import { defineStore } from 'pinia'

export const useGroup = defineStore({
  id: 'group',
  state: () => ({
    groups: [],
    group: {},
    empresa: {},
    activeGroup: {}
  }),
  actions: {
    setGroups(groups) {
      this.groups = groups
    },
    setGroup(group) {
      this.group = group
    },
    setEmpresa(empresa) {
      this.empresa = empresa
    },
    setActiveGroup(group) {
      this.activeGroup = group
    },
    setClearAll() {
      this.groups = []
      this.group = {}
      this.empresa = {}
      this.activeGroup = {}
    }
  },
  getters: {
    getGroups(state) {
      return state.groups
    },
    getGroup(state) {
      return state.group
    },
    getEmpresa(state) {
      return state.empresa
    },
    getActiveGroup(state) {
      return state.activeGroup
    }
  },
  persist: true
})
